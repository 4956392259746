<template>
  <div class="fund-list">
    <div class="title">
      {{ $t('pages_main_059') }}
    </div>
    <div class="cur-list">
      <div
        v-for="(cur, index) in $store.getters.curList"
        :key="index"
        class="cur"
      >
        <div class="cur-name flex-align">
          <img :src="getCountryLogo(cur.curType)">
          <span>{{ cur.curType }}</span>
        </div>
        <div class="balance">
          {{ cur.totalAmount ? parseMoney(cur.totalAmount, cur.curType) : '0.00' }}
        </div>
        <div class="content">
          <div class="available-amount">
            <div class="label">{{$t('pages_main_057')}}</div>
            <div class="value">{{ cur.totalAmount ? parseMoney(cur.totalAmount, cur.curType) : '0.00' }}</div>
          </div>
          <div v-if="cur.freezeAmount !== 0" class="wait-amount">
            <div class="label">{{ $t('pages_main_024') }}</div>
            <div class="value">{{ parseMoney(cur.freezeAmount, cur.curType) }}</div>
          </div>
        </div>
      </div>
      <!-- end for add account -->
      <div class="cur" @click="$router.push('/receive/apply_type')">
        <div class="cur-add">
          <div class="add-mark">+</div>
          <div class="add-text">{{ $t('pages_main_058') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountryLogo, parseMoney } from '@/util'
export default {
  name: 'FundList',
  props: {
  },
  setup(props, ctx) {
    return {
      getCountryLogo,
      parseMoney
    }
  }
}
</script>

<style lang="less" scoped>
  .fund-list {
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      // left align
      text-align: left;
    }
    .cur-list {
      display: flex;
      margin-left: 24px;
      font-weight: 500;
      width: 100%;
      // x 方向超出，自动换行
      flex-wrap: wrap;
      .cur {
        display: flex;
        flex-direction: column;
        // 定义子元素间间隔方式
        justify-content: space-between;
        margin: 20px;
        padding: 20px;
        text-align: left;
        position: relative;
        width: 200px;
        height: 150px;
        background-color: rgb(241,246,254);
        border-radius: 12px;
        color: rgb(111,111,111);
        .cur-add {
          width: 100%;
          height: 100%;
          cursor: pointer; /* 使鼠标变为手形 */
          transition: background-color 0.3s ease; /* 添加过渡效果使背景色变化更平滑 */
          .add-mark {
            font-size: 40px;
            font-weight: 700;
            // center display
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .add-text {
            display: flex;
            font-size: 16px;
            font-weight: 500;
            justify-content: center;
            align-items: center;
          }
        }
        .cur-add:hover {
          color: @main-color /* 鼠标悬停时的背景色 */
        }
        .balance {
          font-size: 16px;
          font-weight: 700;
          margin-top: 10px;
          color: #000;
        }
        .ic-tip {
          color: @main-color;
          top: 2px;
          right: 2px;
          font-size: 15px;
          position: absolute;
        }
        .cur-name {
          display: flex;
          font-size: 16px;
          font-weight: 500;
          img {
            width: 40px;
            height: 30px;
            border-radius: 5px;
          }
          span {
            margin-left: 8px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          .available-amount {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 800;
            margin-top: 16px;
            .label {
              display: flex;
            }
            .value {
              display: flex;
            }
          }
          .wait-amount {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #d30c4b;
            font-size: 12px;
            .label {
              display: flex;
            }
            .value {
              display: flex;
            }
          }
        }
      }
      .cur:first-child {
        //padding-left: 0px;
      }
      .cur:last-child {
        //border-right: 0;
      }
    }
  }
</style>
