<template>
  <div class="todos">
    <div class="title">{{ $t('pages_main_012') }}</div>
    <div class="list">
      <div class="content" @click="$router.push('/receive/manage')">
        <div class="left">{{ $t('pages_main_014') }}</div>
        <a-skeleton v-if="waitLoading" active :paragraph="false" />
        <div v-else class="right">{{ waitInfo.receive }}</div>
      </div>
      <div class="content" @click="$router.push({ path: '/funds/review', query: { key: 'outbound' } })">
        <div class="left">{{ $t('pages_main_021') }}</div>
        <a-skeleton v-if="waitLoading" active :paragraph="false" />
        <div v-else class="right">{{ waitInfo.outPay }}</div>
      </div>
      <div class="content" @click="$router.push({ path: '/funds/review', query: { key: 'inbound' } })">
        <div class="left">{{ $t('pages_main_020') }}</div>
        <a-skeleton v-if="waitLoading" active :paragraph="false" />
        <div v-else class="right">{{ waitInfo.pay }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { _home } from '@/api/index'
export default {
  name: 'Todos',
  setup() {
    const state = reactive({
      waitLoading: true,
      waitInfo: {}
    })

    const loadBusStatistics = async () => {
      state.waitLoading = true
      const res = await _home.getBusStatistics()
      if (res.data.succ) {
        state.waitInfo = res.data.data
      }
      state.waitLoading = false
    }

    const init = () => {
      loadBusStatistics()
    }

    init()
    return {
      ...toRefs(state)
    }
  }
}

</script>

<style lang="less" scoped>
  .todos {
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      // left align
      text-align: left;
    }
    .list {
      margin-left: 20px;
      .content {
        display: flex;
        flex-direction: row;
        width: 500px;
        height: 50px;
        justify-content: space-between;
        margin-top: 20px;
        border: 1px solid;
        border-color: rgb(180, 172, 172);
        border-radius: 10px;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          color: rgb(111,111,111);
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 20px;
          font-size: 16px;
          font-weight: 500;
          color: @main-color;
          cursor: pointer;
        }
      }
    }
  }
</style>
