<template>
  <div id="homeIndex">
    <!-- <div class="card-list">
      <div
        v-for="(card, index) in cardOpts"
        :key="index"
        class="card-area flex-align"
        :style="{background: card.color}"
        @click="$router.push(card.link)"
      >
        <img :src="card.icon" class="card-left">
        <div class="card-right">
          <div class="title">{{ card.title }}</div>
          <div class="desc">{{ card.desc }}</div>
        </div>
      </div>
    </div> -->
    <fund-list />
    <todos />
    <!-- <area-echarts /> -->
    <!-- <quick-module /> -->
  </div>
</template>
<script>
import FundList from '@/components/fund-list'
// import AreaEcharts from './components/area-echarts'
// import QuickModule from './components/quick-module'
import Todos from '@/components/todos.vue'
import { reactive, toRefs, h } from 'vue'
import { useStore } from 'vuex'
import { notification, Button } from 'ant-design-vue'
import { _home } from '@/api'
import i18n from '@/locale'
export default {
  name: 'HomeIndex',
  components: {
    'fund-list': FundList,
    // 'area-echarts': AreaEcharts,
    // 'quick-module': QuickModule
    'todos': Todos
  },
  setup() {
    const store = useStore()
    const state = reactive({
      cardOpts: [
        { title: i18n.global.t('pages_main_001'), desc: i18n.global.t('pages_main_005'), icon: require('../../assets/icons/ic-tab-apply.png'), color: '#7E97FB', link: '/receive/apply_type' },
        { title: i18n.global.t('pages_main_002'), desc: i18n.global.t('pages_main_006'), icon: require('../../assets/icons/ic-tab-receive.png'), color: '#60B2DC', link: '/receive/manage' },
        { title: i18n.global.t('pages_main_020'), desc: i18n.global.t('pages_main_007'), icon: require('../../assets/icons/ic-tab-exchange.png'), color: '#1DDEE5', link: '/funds/inbounds' },
        { title: i18n.global.t('pages_main_021'), desc: i18n.global.t('pages_main_008'), icon: require('../../assets/icons/ic-tab-pay.png'), color: '#1ED1B8', link: '/funds/outbounds' }
      ]
    })

    const loadNoticeInfo = async () => {
      const noticed = sessionStorage.getItem('noticed')
      if (noticed) return
      const userInfo = store.getters.getUserInfo
      const res = await _home.getNoticeInfo({ merchantNo: userInfo.merchantNo })
      if (res.data.succ) {
        let count = 0
        let noticeInfo = ''
        let language = localStorage.getItem('locale-language')
        const { kycMsg, noCheckedProList, smsTextCn, smsTextEn } = res.data.data
        if (!language) language = (navigator.language || navigator.browserLanguage).toLowerCase().substr(0, 2)

        if (language === 'zh') {
          noCheckedProList.map(item => {
            if (item.id === 4) {
              if (item.checkStatus === '00') {
                noticeInfo = ++count + '.国际汇款产品校验提醒：请先提交国际汇款交易支持材料，以免影响国际汇款交易。' + '\n'
              } else if (item.checkStatus === '03') {
                noticeInfo = ++count + '.国际汇款产品校验提醒：您提交的支持材料审核未通过，请重新提交，以免影响国际汇款交易。' + '\n'
              }
            }
          })
          if (smsTextCn) noticeInfo += ++count + '.' + smsTextCn + '\n'
          if (kycMsg) noticeInfo += ++count + '.' + kycMsg + '\n'
        } else {
          noCheckedProList.forEach(item => {
            if (item.id === 4) {
              noticeInfo = ++count + '.International remittance product reminder: please submit the international remittance transaction support materials first, so as not to affect the international remittance transaction.' + '\n'
            } else if (item.checkStatus === '03') {
              noticeInfo = ++count + '.International remittance product reminder: The supporting materials you submitted unapproved, please resubmit so as not to affect the international remittance transaction.' + '\n'
            }
          })
          if (smsTextEn) noticeInfo += ++count + '.' + smsTextEn + '\n'
          if (kycMsg) noticeInfo += ++count + '.' + kycMsg + '\n'
        }

        if (noticeInfo) {
          notification.warning({
            message: i18n.global.t('pages_main_054'),
            description: h('div', { style: { whiteSpace: 'pre-wrap' }}, noticeInfo),
            duration: null,
            btn: () => {
              return h(Button, {
                type: 'primary',
                size: 'small',
                onClick: () => {
                  notification.destroy()
                  sessionStorage.setItem('noticed', true)
                }
              }, i18n.global.t('pages_main_055'))
            }
          })
        }
      }
    }

    if (process.env.VUE_APP_SAASNAME === 'OTT') loadNoticeInfo()

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#homeIndex {
  .card-list {
    display: flex;
    .card-area {
      flex: 0 0 24.25%;
      margin-right: 1%;
      color: #fff;
      padding: 14px;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      .card-left {
        width: 40px;
      }
      .card-right {
        text-align: left;
        margin-left: 5px;
        overflow: hidden;
        .title {
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
  }
}
</style>

